<template>
    <div class="px-4 md:px-16">
        <div class="flex space-x-4 mb-4 lg:mb-7">
            <div @click="$emit('type', '')">
                <svgicon name="chevronPrev" width="36" height="36" ></svgicon>
            </div>
            <div >
                <p class="lg:text-2xl font-bold">Formulaire de collecte des données pour entreprise</p>
                <p class="mt-1 text-sm lg:text-base">Mise à jour de la base de données afin de répondre aux exigences légales et règlementaires</p>
            </div>
            
        </div>
        
        <p v-if="existingAccountNumber" class="my-6 flex space-x-4 items-center bg-errorBg rounded-lg p-3 py-2.5">
            <span>
                <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 1.3335C4.32004 1.3335 1.33337 4.32016 1.33337 8.00016C1.33337 11.6802 4.32004 14.6668 8.00004 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 8.00004 1.3335ZM8.00004 8.66683C7.63337 8.66683 7.33337 8.36683 7.33337 8.00016V5.3335C7.33337 4.96683 7.63337 4.66683 8.00004 4.66683C8.36671 4.66683 8.66671 4.96683 8.66671 5.3335V8.00016C8.66671 8.36683 8.36671 8.66683 8.00004 8.66683ZM7.33337 10.0002V11.3335H8.66671V10.0002H7.33337Z" fill="black" />
                    </mask>
                    <g mask="url(#mask0)">
                        <rect width="16" height="16" fill="#DA1414" />
                    </g>
                </svg>
            </span>
            <span class="text-sm lg:text-lg">Le numéro de compte entré à dejà été enregistré pour une collecte</span>
        </p>

        <div class="mb-6 text-xs flex justify-center items-center">
            <div class="flex space-x-3 items-center font-medium w-1/3 justify-center border-b-2 border-secondary pb-3" :class="step==1 ? 'border-primary' : step > 1 ? 'border-stepPass' : ''">
                <p class="mb-1 rounded-full flex items-center justify-center" :class="step == 1 ? 'text-white h-8 w-8 bg-primary text-lg' : (step > 1 ? 'h-8 w-8 bg-stepPass text-black' : 'h-8 w-8  bg-bgGrey  text-fonctGrey')">
                    <span v-if="step <= 1">1</span>
                    <span v-else> <svgicon name="checkStep" width="20" height="20" ></svgicon></span>
                </p>
                <p class="hidden md:inline text-center" :class="step == 1 ? 'text-black' : (step > 1 ? 'text-blueDark':'text-fonctGrey')">Identification</p>
            </div>
            <div class="flex space-x-3 items-center font-medium w-1/3 justify-center border-b-2 border-secondary pb-3" :class="step==2 ? 'border-primary' : step > 2 ? 'border-stepPass' : ''">
                <p class="mb-1 rounded-full flex items-center justify-center" :class="step == 2 ? 'text-white h-8 w-8 bg-primary text-lg' : (step > 2 ? 'h-8 w-8 bg-stepPass text-black' : 'h-8 w-8  bg-bgGrey  text-fonctGrey')">
                    <span v-if="step <= 2">2</span>
                    <span v-else> <svgicon name="checkStep" width="20" height="20" ></svgicon></span>
                </p>
                <p class="hidden md:inline text-center" :class="step == 2 ? 'text-black' : (step > 2 ? 'text-blueDark':'text-fonctGrey')">Principaux dirigeants</p>
            </div>
            <div  class="flex space-x-3 items-center font-medium w-1/3 justify-center border-b-2 border-secondary pb-3" :class="step==3 ? 'border-primary' : step > 3 ? 'border-stepPass' : ''">
                <p class="mb-1 rounded-full flex items-center justify-center" :class="step == 3 ? 'text-white h-8 w-8 bg-primary text-lg' : (step > 3 ? 'h-8 w-8 bg-stepPass text-black' : 'h-8 w-8  bg-bgGrey  text-fonctGrey')">
                    <span v-if="step <= 3">3</span>
                    <span v-else> <svgicon name="checkStep" width="20" height="20" ></svgicon></span>
                </p>
                <p class="hidden md:inline text-center" :class="step == 3 ? 'text-black' : (step > 3 ? 'text-blueDark':'text-fonctGrey')">Références bancaires</p>
            </div>
        </div>

        <div>
            <ValidationObserver ref="form">
                <form @submit.prevent="onSubmit" class="">
                    <div v-if="step == 1">
                        <div class="mb-6">
                            <ValidationProvider name="Le numéro de compte principal" rules="required|max:11" v-slot="{ errors }">
                                <m-input name = "accountNumber" v-model="enterprise.principalAccountNumber" maxlength="11" :errors="errors[0]"  type="" min="0" label="Numéro de compte principal"/>
                            </ValidationProvider>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between"> 
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Forme Juridique" rules="required" v-slot="{ errors }">
                                    <label class="font-bold" for="">Forme Juridique <span class='text-red-500'>*</span></label>
                                    <m-select :errors="errors[0]" class="mt-1" v-model="enterprise.juridicForm" :initialValue="enterprise.juridicForm" :options="jurificFormOptions" placeholder="Cliquer pour sélectionner" ></m-select>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Raison sociale" rules="required" v-slot="{ errors }">
                                    <m-input name = "reason" v-model="enterprise.socialReason" :errors="errors[0]"  label="Raison sociale <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Type de société" rules="required" v-slot="{ errors }">
                                    <label class="font-bold" for="">Type de société <span class='text-red-500'>*</span></label>
                                    <m-select :errors="errors[0]" class="mt-1" v-model="enterprise.societyType" :initialValue="enterprise.societyType" :options="societyTypeOptions" placeholder="Cliquer pour sélectionner" ></m-select>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Date de création" rules="required" v-slot="{ errors }">
                                    <!-- <m-input name = "creationDate" type="date" v-model="enterprise.creationDate" :errors="errors[0]"  label="Date de création <span class='text-red-500'>*</span>"/> -->
                                    <vc-date-picker v-model="enterprise.creationDate" :max-date="new Date()">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <m-input name = "creationDate" :value="inputValue" v-on="inputEvents" :errors="errors[0]"  label="Date de création <span class='text-red-500'>*</span>">
                                                <span slot="prepend"><svgicon name="calendar" width="24" height="25"></svgicon></span>
                                            </m-input>
                                        </template>
                                    </vc-date-picker>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Nature des activités" rules="required" v-slot="{ errors }">
                                    <m-input name = "activity" v-model="enterprise.activityNature" :errors="errors[0]" label="Nature des activités <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Date d'immatriculation" rules="required" v-slot="{ errors }">
                                    <vc-date-picker v-model="enterprise.immatDate" :max-date="new Date()">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <m-input name = "immatriculationDate" :value="inputValue" v-on="inputEvents" :errors="errors[0]"  label="Date d'immatriculation <span class='text-red-500'>*</span>">
                                                <span slot="prepend"><svgicon name="calendar" width="24" height="25"></svgicon></span>
                                            </m-input>
                                        </template>
                                    </vc-date-picker>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Numéro d'immatriculation RCCM " rules="required" v-slot="{ errors }">
                                    <m-input name = "immaNumber" v-model="enterprise.rccmImmat" :errors="errors[0]" label="Numéro d'immatriculation RCCM  <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Capital social" rules="required|numeric" v-slot="{ errors }">
                                    <m-input name = "socialCapital" v-model="enterprise.socialCapital" :errors="errors[0]"  label="Capital social <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Numéro compte contribuable " rules="required" v-slot="{ errors }">
                                    <m-input name = "contribAccount" v-model="enterprise.contribAccountNumb" :errors="errors[0]" label="Numéro compte contribuable  <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La nationalité" rules="required" v-slot="{ errors }">
                                    <m-input name = "nationality" v-model="enterprise.nationality" :errors="errors[0]"  label="Nationalité <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Adresse postale " rules="required" v-slot="{ errors }">
                                    <m-input name = "postAdd" v-model="enterprise.postAddress" :errors="errors[0]" label="Adresse postale <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Adresse géographique " rules="required" v-slot="{ errors }">
                                    <m-input name = "nationality" v-model="enterprise.geoAddress" :errors="errors[0]"  label="Adresse géographique <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Siège sociale" rules="required" v-slot="{ errors }">
                                    <m-input name = "siege" v-model="enterprise.socialSiege" :errors="errors[0]"  label="Siège sociale <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Pays" rules="required" v-slot="{ errors }">
                                    <label class="font-bold" for="">Pays <span class='text-red-500'>*</span></label>
                                    <m-select :errors="errors[0]" class="mt-1" v-model="enterprise.country" :initialValue="enterprise.country" :options="countries" placeholder="Cliquer pour sélectionner" ></m-select>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Fax" rules="required" v-slot="{ errors }">
                                    <m-input name = "fax" v-model="enterprise.fax" :errors="errors[0]"  type="" label="Fax <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
                                    <m-input name = "email" v-model="enterprise.email" :errors="errors[0]"  type="" label="Email <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le numéro de téléphone" rules="required" v-slot="{ errors }">
                                    <m-input name = "phone" v-model="enterprise.phone" :errors="errors[0]"  type="" label="Numéro de téléphone <span class='text-red-500'>*</span>"/>
                                    <span class="text-xs text-fonctGrey">Possibilité de saisir jusqua’à 3 numéros à séparer par des “;”</span>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Site web" >
                                    <m-input name = "webSite"  v-model="enterprise.webSite" label="Site web"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Chiffres d'affaires moyens annuels" rules="required|numeric" v-slot="{ errors }">
                                    <m-input name = "affNumber" v-model="enterprise.avgBusinessMoney" :errors="errors[0]"  type="" min="0" label="Chiffres d'affaires moyens annuels <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="N° d'autorisation/agrément" rules="required" v-slot="{ errors }">
                                    <m-input name = "agreement" :errors="errors[0]" v-model="enterprise.autAgreement" label="N° d'autorisation/agrément pour les activités réglementés <span class='text-red-500'>*</span>"/>
                                    <span class="text-xs text-fonctGrey">Pour les GVC, association et toutes coopératives</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="mb-6">
                            <ValidationProvider name="N° Décret ou arrêté ministériel " rules="required" v-slot="{ errors }">
                                <m-input name = "decret" :errors="errors[0]" v-model="enterprise.ministerialDecret" label="N° Décret ou arrêté ministériel  <span class='text-red-500'>*</span>"/>
                                <span class="text-xs text-fonctGrey">Pour des démembrements institutionnels de l'état</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div v-if="step == 2">
                        <div  v-for="dirigeant in enterprise.dirigeants" :key="dirigeant.id">
                            <ValidationObserver >
                                <form  @submit.prevent="addDirigeant(dirigeant)" >
                                    <hr v-show="dirigeant != 1" class="bg-stepPass mb-4 lg:mb-6">
                                    <div class="mb-6">
                                        <ValidationProvider name="Noms et prénoms / raison sociale" rules="required" v-slot="{ errors }">
                                            <m-input v-model="enterprise.dirigeants[dirigeant.id].name" :errors="errors[0]" name = "name"  label="Noms et prénoms / raison sociale <span class='text-red-500'>*</span>"/>
                                        </ValidationProvider>
                                    </div>
                                    <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="Type de dirigeant" rules="required" v-slot="{ errors }">
                                                <m-input v-model="enterprise.dirigeants[dirigeant.id].type" :errors="errors[0]" name = "type"  label="Type de dirigeant <span class='text-red-500'>*</span>"/>
                                            </ValidationProvider>
                                        </div>
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="Fonction" rules="required" v-slot="{ errors }">
                                                <m-input v-model="enterprise.dirigeants[dirigeant.id].function" :errors="errors[0]" name = "function"  label="Fonction <span class='text-red-500'>*</span>"/>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="La date de naissance" rules="required" v-slot="{ errors }">
                                                <vc-date-picker v-model="enterprise.dirigeants[dirigeant.id].birthDate" :max-date="new Date()">
                                                    <template v-slot="{ inputValue, inputEvents }">
                                                        <m-input name = "birth" :value="inputValue" v-on="inputEvents" :errors="errors[0]"  label="Date de naissance <span class='text-red-500'>*</span>">
                                                            <span slot="prepend"><svgicon name="calendar" width="24" height="25"></svgicon></span>
                                                        </m-input>
                                                    </template>
                                                </vc-date-picker>
                                            </ValidationProvider>
                                        </div>
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="Le lieu de naissance" rules="required" v-slot="{ errors }">
                                                <m-input name = "birthCity" v-model="enterprise.dirigeants[dirigeant.id].birthCity" :errors="errors[0]" label="Lieu de naissance <span class='text-red-500'>*</span>"/>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="Nationalité" rules="required" v-slot="{ errors }">
                                                <m-input name = "nationality" v-model="enterprise.dirigeants[dirigeant.id].nationality" :errors="errors[0]"  label="Nationalité <span class='text-red-500'>*</span>"/>
                                            </ValidationProvider>
                                        </div>
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="Pays de résidence" rules="required" v-slot="{ errors }">
                                                <label class="font-bold" for="">Pays de résidence <span class='text-red-500'>*</span></label>
                                                <m-select :errors="errors[0]" class="mt-1" v-model="enterprise.dirigeants[dirigeant.id].residenceCountry" :initialValue="enterprise.dirigeants[dirigeant.id].residenceCountry" :options="countries" placeholder="Cliquer pour sélectionner" ></m-select>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="Ville de résidence" rules="required" v-slot="{ errors }">
                                                <m-input name = "city" v-model="enterprise.dirigeants[dirigeant.id].residenceCity" :errors="errors[0]"  label="Ville de résidence <span class='text-red-500'>*</span>"/>
                                            </ValidationProvider>
                                        </div>
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="% Capital détenu" rules="required|numeric" v-slot="{ errors }">
                                                <m-input name = "capital" v-model="enterprise.dirigeants[dirigeant.id].capital" :errors="errors[0]"  label="% Capital détenu <span class='text-red-500'>*</span>"/>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="La nature de la pièce d’identité " rules="required" v-slot="{ errors }">
                                                <label class="font-bold text-sm" for="">Nature de la pièce d’identité  <span class='text-red-500'>*</span></label>
                                                <m-select :errors="errors[0]" v-model="enterprise.dirigeants[dirigeant.id].idCardType" class="mt-1" :initialValue="enterprise.dirigeants[dirigeant.id].idCardType" :options="idTypeOptions" placeholder="Cliquer pour sélectionner" ></m-select>
                                            </ValidationProvider>
                                        </div>
                                        <div class="mb-6 md:w-1/2">
                                            <ValidationProvider name="Le numéro de pièce d'identité" rules="required" v-slot="{ errors }">
                                                <m-input name = "idNumber" v-model="enterprise.dirigeants[dirigeant.id].idCardFile" :errors="errors[0]"  label="Numéro de la pièce d’identité  <span class='text-red-500'>*</span>"/>
                                            </ValidationProvider>
                                        </div>
                                    </div>
                                    <div class="flex space-x-4 lg:space-x-6 mb-6 lg:mb-10">
                                        <MButton v-if="dirigeant.id == dirigeantNumber"  type="submit" :text="'Ajouter un dirigeant'" :classes="'rounded bg-statusBg text-sm font-medium flex space-x-2 items-center p-2'">
                                            <span slot="icon">
                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.0052 10.8334H10.8385V15.0001C10.8385 15.4584 10.4635 15.8334 10.0052 15.8334C9.54688 15.8334 9.17188 15.4584 9.17188 15.0001V10.8334H5.00521C4.54688 10.8334 4.17188 10.4584 4.17188 10.0001C4.17188 9.54175 4.54688 9.16675 5.00521 9.16675H9.17188V5.00008C9.17188 4.54175 9.54688 4.16675 10.0052 4.16675C10.4635 4.16675 10.8385 4.54175 10.8385 5.00008V9.16675H15.0052C15.4635 9.16675 15.8385 9.54175 15.8385 10.0001C15.8385 10.4584 15.4635 10.8334 15.0052 10.8334Z" fill="#2E5AAC"/>
                                                </svg>
                                            </span>
                                        </MButton>
                                        <MButton v-if="dirigeant.id != 0 && enterprise.dirigeants.length == dirigeantsIds.length || dirigeant.id != 0 && dirigeant.id < dirigeantNumber" @click="removeDirigeant(dirigeant.id)" :text="'Supprimer ce dirigeantserver'" :classes="'rounded bg-errorBg text-sm font-medium flex space-x-2 items-center p-2'">
                                            <span slot="icon">
                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.2545 4.75842C15.0988 4.60238 14.8874 4.51468 14.667 4.51468C14.4466 4.51468 14.2352 4.60238 14.0795 4.75842L10.0045 8.82508L5.92949 4.75009C5.7738 4.59404 5.56242 4.50635 5.34199 4.50635C5.12156 4.50635 4.91019 4.59404 4.75449 4.75009C4.42949 5.07509 4.42949 5.60009 4.75449 5.92509L8.82949 10.0001L4.75449 14.0751C4.42949 14.4001 4.42949 14.9251 4.75449 15.2501C5.07949 15.5751 5.60449 15.5751 5.92949 15.2501L10.0045 11.1751L14.0795 15.2501C14.4045 15.5751 14.9295 15.5751 15.2545 15.2501C15.5795 14.9251 15.5795 14.4001 15.2545 14.0751L11.1795 10.0001L15.2545 5.92509C15.5712 5.60842 15.5712 5.07509 15.2545 4.75842Z" fill="#DA1414"/>
                                                </svg>
                                            </span>
                                        </MButton>
                                        <MButton v-if=" enterprise.dirigeants.length != dirigeantsIds.length && dirigeant.id != 0 && dirigeant.id == dirigeantNumber" @click="removeDirigeantNotAdd()" :text="'Supprimer ce dirigeant'" :classes="'rounded bg-errorBg text-sm font-medium flex space-x-2 items-center p-2'">
                                            <span slot="icon">
                                                <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15.2545 4.75842C15.0988 4.60238 14.8874 4.51468 14.667 4.51468C14.4466 4.51468 14.2352 4.60238 14.0795 4.75842L10.0045 8.82508L5.92949 4.75009C5.7738 4.59404 5.56242 4.50635 5.34199 4.50635C5.12156 4.50635 4.91019 4.59404 4.75449 4.75009C4.42949 5.07509 4.42949 5.60009 4.75449 5.92509L8.82949 10.0001L4.75449 14.0751C4.42949 14.4001 4.42949 14.9251 4.75449 15.2501C5.07949 15.5751 5.60449 15.5751 5.92949 15.2501L10.0045 11.1751L14.0795 15.2501C14.4045 15.5751 14.9295 15.5751 15.2545 15.2501C15.5795 14.9251 15.5795 14.4001 15.2545 14.0751L11.1795 10.0001L15.2545 5.92509C15.5712 5.60842 15.5712 5.07509 15.2545 4.75842Z" fill="#DA1414"/>
                                                </svg>
                                            </span>
                                        </MButton>
                                    </div>
                                </form>
                            </ValidationObserver>
                        </div>
                        
                    </div>

                    <div v-if="step == 3">
                        <div class="mb-6">
                            <p class="font-bold mb-4 text-sm">Disposez-vous d'un compte dans une autre banque ? <span class='text-red-500'>*</span></p>
                            <p class="flex space-x-10 items-center font-medium text-sm">
                                <label for="">
                                    <input type="radio" :value="true" v-model="enterprise.haveOtherBankAccount" name="otherBank" checked class="form-radio text-blueNormal border border-blue-600" id=""> Oui
                                </label>
                                <label for="">
                                        <input type="radio" :value="false" v-model="enterprise.haveOtherBankAccount" name="otherBank" class="form-radio text-blueNormal border border-blue-600" id=""> Non
                                </label>
                            </p>
                        </div>
                        <div v-if="enterprise.haveOtherBankAccount">
                            <div v-for="(account,i) in otherAccount" :key="i">
                                <hr v-show="account != 1" class="bg-stepPass mb-4 lg:mb-6">
                                <div class="mb-6">
                                    <ValidationProvider name="Indiquez le nom de la banque" rules="required" v-slot="{ errors }">
                                            <m-input v-model="enterprise.otherBankNames[i]" :errors="errors[0]" name = "bankName"  label="Indiquez le nom de la banque <span class='text-red-500'>*</span>"/>
                                    </ValidationProvider>
                                </div>
                                <div class="flex space-x-4 lg:space-x-6 mb-6 lg:mb-10">
                                    <MButton v-if="account == otherAccount" @click="addOtherBankName" :text="'Ajouter une autre banque'" :classes="'rounded bg-statusBg text-sm font-medium flex space-x-2 items-center p-2'">
                                        <span slot="icon">
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.0052 10.8334H10.8385V15.0001C10.8385 15.4584 10.4635 15.8334 10.0052 15.8334C9.54688 15.8334 9.17188 15.4584 9.17188 15.0001V10.8334H5.00521C4.54688 10.8334 4.17188 10.4584 4.17188 10.0001C4.17188 9.54175 4.54688 9.16675 5.00521 9.16675H9.17188V5.00008C9.17188 4.54175 9.54688 4.16675 10.0052 4.16675C10.4635 4.16675 10.8385 4.54175 10.8385 5.00008V9.16675H15.0052C15.4635 9.16675 15.8385 9.54175 15.8385 10.0001C15.8385 10.4584 15.4635 10.8334 15.0052 10.8334Z" fill="#2E5AAC"/>
                                            </svg>
                                        </span>
                                    </MButton>
                                    <MButton v-if="account != 1 && account == otherAccount" @click="removeOtherBankName" :text="'Supprimer cette banque'" :classes="'rounded bg-errorBg text-sm font-medium flex space-x-2 items-center p-2'">
                                        <span slot="icon">
                                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.2545 4.75842C15.0988 4.60238 14.8874 4.51468 14.667 4.51468C14.4466 4.51468 14.2352 4.60238 14.0795 4.75842L10.0045 8.82508L5.92949 4.75009C5.7738 4.59404 5.56242 4.50635 5.34199 4.50635C5.12156 4.50635 4.91019 4.59404 4.75449 4.75009C4.42949 5.07509 4.42949 5.60009 4.75449 5.92509L8.82949 10.0001L4.75449 14.0751C4.42949 14.4001 4.42949 14.9251 4.75449 15.2501C5.07949 15.5751 5.60449 15.5751 5.92949 15.2501L10.0045 11.1751L14.0795 15.2501C14.4045 15.5751 14.9295 15.5751 15.2545 15.2501C15.5795 14.9251 15.5795 14.4001 15.2545 14.0751L11.1795 10.0001L15.2545 5.92509C15.5712 5.60842 15.5712 5.07509 15.2545 4.75842Z" fill="#DA1414"/>
                                            </svg>
                                        </span>
                                    </MButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="flex space-x-4 md:space-x-6 justify-between">
                        <div :class="step !=1 ? 'w-1/2' : 'hidden'">
                            <m-button @click="step--" :classes="'font-bold text-sm sm:text-base bg-bgGrey rounded-lg p-2 w-full'" :text="'Retour'"></m-button>
                        </div>
                        <div v-if="step == 1" class="md:w-1/2"></div>
                        <div :class="step !=1 ? 'w-1/2' : 'md:w-1/2'">
                            <m-button :type="'submit'" :classes="'text-white font-bold text-sm sm:text-base bg-primary rounded-lg p-2 w-full'" load :activateLoad="request" :text="'Valider'"></m-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
//const directAxios = axios.create()
const token = localStorage.getItem('token')
const header = { "Authorization": `Bearer ${token}` }
const instanceApi = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: header
})

export default {
    data() {
        return {
            existingAccountNumber: false,
            request: false,
            deliveryCss: {modalClass:"p-4 md:px-6 overflow-x-hidden overflow-y-auto hiddScroll"},
            step: 1,
            totalSteps: 3,
            dirigeantNumber: 0,
            otherAccount: 1,
            jurificFormOptions: [
                {label:'SARL'},
                {label:'SOCIETE ANONYME'},
                {label:'COOPERATIVE'},
                {label:'GVC'},
                {label:'ETAT'},
                {label:'GOUVERNEMENT'},
                {label:'CLUB'},
                {label:'ONG'},
                {label:'ASSOCIATION'},
                {label:'AMBASSADE'},
                {label:'REPRESENTATION'},
                {label:'DIPLOMATIQUE'},
                /* {label:'AUTRES'}, */
                    
            ],
            idTypeOptions: [
                {label:'CNI'},
                {label:'Passeport'},
                {label:'Carte consulaire'},
                {label:'Attestation d’identité'},
                {label:'Carte de séjour'},
            ],
            societyTypeOptions: [
                {label:'SOCIETE LOCALE'},
                {label:'SOCIETE REGIONALE'},
                {label:'MULTINATIONALE'},
               /*  {label:'AUTRE ( à préciser)'}, */
            ],
            revenuOptions: [
                {label:'Inférieur à 200 milles'},
                {label:'Entre 200 milles et 700 milles'},
                {label:'Entre 700 milles et 2 millions'},
                {label:'Supérieur à 2 millions'},
            ],
            enterprise: {
                principalAccountNumber: '',
                juridicForm: '',
                socialReason: '',
                societyType: '',
                creationDate: '',
                activityNature: '',
                immatDate: '',
                rccmImmat: '',
                socialCapital: '',
                contribAccountNumb: '',
                nationality: '',
                postAddress: '',
                geoAddress: '',
                socialSiege: '',
                country: '',
                fax: '',
                email: '',
                phone: '',
                webSite: '',
                avgBusinessMoney: '',
                autAgreement: '',
                ministerialDecret: '',
                dirigeants: [{
                    id: 0,
                    name: '',
                    type: '',
                    function: '',
                    birthDate: '',
                    birthCity: '',
                    nationality: '',
                    residenceCountry: '',
                    residenceCity: '',
                    capital: '',
                    idCardType: '',
                    idCardFile: ''
                }],
                haveOtherBankAccount: false,
                otherBankNames: []
            },
            dirigeantsIds: [],
            countriesData: [
                {"id":4,"name":"Afghanistan","alpha2":"af","alpha3":"afg"},
                {"id":710,"name":"Afrique du Sud","alpha2":"za","alpha3":"zaf"},
                {"id":8,"name":"Albanie","alpha2":"al","alpha3":"alb"},
                {"id":12,"name":"Algérie","alpha2":"dz","alpha3":"dza"},
                {"id":276,"name":"Allemagne","alpha2":"de","alpha3":"deu"},
                {"id":20,"name":"Andorre","alpha2":"ad","alpha3":"and"},
                {"id":24,"name":"Angola","alpha2":"ao","alpha3":"ago"},
                {"id":28,"name":"Antigua-et-Barbuda","alpha2":"ag","alpha3":"atg"},
                {"id":682,"name":"Arabie saoudite","alpha2":"sa","alpha3":"sau"},
                {"id":32,"name":"Argentine","alpha2":"ar","alpha3":"arg"},
                {"id":51,"name":"Arménie","alpha2":"am","alpha3":"arm"},
                {"id":36,"name":"Australie","alpha2":"au","alpha3":"aus"},
                {"id":40,"name":"Autriche","alpha2":"at","alpha3":"aut"},
                {"id":31,"name":"Azerbaïdjan","alpha2":"az","alpha3":"aze"},
                {"id":44,"name":"Bahamas","alpha2":"bs","alpha3":"bhs"},
                {"id":48,"name":"Bahreïn","alpha2":"bh","alpha3":"bhr"},
                {"id":50,"name":"Bangladesh","alpha2":"bd","alpha3":"bgd"},
                {"id":52,"name":"Barbade","alpha2":"bb","alpha3":"brb"},
                {"id":112,"name":"Biélorussie","alpha2":"by","alpha3":"blr"},
                {"id":56,"name":"Belgique","alpha2":"be","alpha3":"bel"},
                {"id":84,"name":"Belize","alpha2":"bz","alpha3":"blz"},
                {"id":204,"name":"Bénin","alpha2":"bj","alpha3":"ben"},
                {"id":64,"name":"Bhoutan","alpha2":"bt","alpha3":"btn"},
                {"id":68,"name":"Bolivie","alpha2":"bo","alpha3":"bol"},
                {"id":70,"name":"Bosnie-Herzégovine","alpha2":"ba","alpha3":"bih"},
                {"id":72,"name":"Botswana","alpha2":"bw","alpha3":"bwa"},
                {"id":76,"name":"Brésil","alpha2":"br","alpha3":"bra"},
                {"id":96,"name":"Brunei","alpha2":"bn","alpha3":"brn"},
                {"id":100,"name":"Bulgarie","alpha2":"bg","alpha3":"bgr"},
                {"id":854,"name":"Burkina Faso","alpha2":"bf","alpha3":"bfa"},
                {"id":108,"name":"Burundi","alpha2":"bi","alpha3":"bdi"},
                {"id":116,"name":"Cambodge","alpha2":"kh","alpha3":"khm"},
                {"id":120,"name":"Cameroun","alpha2":"cm","alpha3":"cmr"},
                {"id":124,"name":"Canada","alpha2":"ca","alpha3":"can"},
                {"id":132,"name":"Cap-Vert","alpha2":"cv","alpha3":"cpv"},
                {"id":140,"name":"République centrafricaine","alpha2":"cf","alpha3":"caf"},
                {"id":152,"name":"Chili","alpha2":"cl","alpha3":"chl"},
                {"id":156,"name":"Chine","alpha2":"cn","alpha3":"chn"},
                {"id":196,"name":"Chypre (pays)","alpha2":"cy","alpha3":"cyp"},
                {"id":170,"name":"Colombie","alpha2":"co","alpha3":"col"},
                {"id":174,"name":"Comores (pays)","alpha2":"km","alpha3":"com"},
                {"id":178,"name":"République du Congo","alpha2":"cg","alpha3":"cog"},
                {"id":180,"name":"République démocratique du Congo","alpha2":"cd","alpha3":"cod"},
                {"id":410,"name":"Corée du Sud","alpha2":"kr","alpha3":"kor"},
                {"id":408,"name":"Corée du Nord","alpha2":"kp","alpha3":"prk"},
                {"id":188,"name":"Costa Rica","alpha2":"cr","alpha3":"cri"},
                {"id":384,"name":"Côte d'Ivoire","alpha2":"ci","alpha3":"civ"},
                {"id":191,"name":"Croatie","alpha2":"hr","alpha3":"hrv"},
                {"id":192,"name":"Cuba","alpha2":"cu","alpha3":"cub"},
                {"id":208,"name":"Danemark","alpha2":"dk","alpha3":"dnk"},
                {"id":262,"name":"Djibouti","alpha2":"dj","alpha3":"dji"},
                {"id":214,"name":"République dominicaine","alpha2":"do","alpha3":"dom"},
                {"id":212,"name":"Dominique","alpha2":"dm","alpha3":"dma"},
                {"id":818,"name":"Égypte","alpha2":"eg","alpha3":"egy"},
                {"id":222,"name":"Salvador","alpha2":"sv","alpha3":"slv"},
                {"id":784,"name":"Émirats arabes unis","alpha2":"ae","alpha3":"are"},
                {"id":218,"name":"Équateur (pays)","alpha2":"ec","alpha3":"ecu"},
                {"id":232,"name":"Érythrée","alpha2":"er","alpha3":"eri"},
                {"id":724,"name":"Espagne","alpha2":"es","alpha3":"esp"},
                {"id":233,"name":"Estonie","alpha2":"ee","alpha3":"est"},
                {"id":840,"name":"États-Unis","alpha2":"us","alpha3":"usa"},
                {"id":231,"name":"Éthiopie","alpha2":"et","alpha3":"eth"},
                {"id":242,"name":"Fidji","alpha2":"fj","alpha3":"fji"},
                {"id":246,"name":"Finlande","alpha2":"fi","alpha3":"fin"},
                {"id":250,"name":"France","alpha2":"fr","alpha3":"fra"},
                {"id":266,"name":"Gabon","alpha2":"ga","alpha3":"gab"},
                {"id":270,"name":"Gambie","alpha2":"gm","alpha3":"gmb"},
                {"id":268,"name":"Géorgie (pays)","alpha2":"ge","alpha3":"geo"},
                {"id":288,"name":"Ghana","alpha2":"gh","alpha3":"gha"},
                {"id":300,"name":"Grèce","alpha2":"gr","alpha3":"grc"},
                {"id":308,"name":"Grenade (pays)","alpha2":"gd","alpha3":"grd"},
                {"id":320,"name":"Guatemala","alpha2":"gt","alpha3":"gtm"},
                {"id":324,"name":"Guinée","alpha2":"gn","alpha3":"gin"},
                {"id":624,"name":"Guinée-Bissau","alpha2":"gw","alpha3":"gnb"},
                {"id":226,"name":"Guinée équatoriale","alpha2":"gq","alpha3":"gnq"},
                {"id":328,"name":"Guyana","alpha2":"gy","alpha3":"guy"},
                {"id":332,"name":"Haïti","alpha2":"ht","alpha3":"hti"},
                {"id":340,"name":"Honduras","alpha2":"hn","alpha3":"hnd"},
                {"id":348,"name":"Hongrie","alpha2":"hu","alpha3":"hun"},
                {"id":356,"name":"Inde","alpha2":"in","alpha3":"ind"},
                {"id":360,"name":"Indonésie","alpha2":"id","alpha3":"idn"},
                {"id":364,"name":"Iran","alpha2":"ir","alpha3":"irn"},
                {"id":368,"name":"Irak","alpha2":"iq","alpha3":"irq"},
                {"id":372,"name":"Irlande (pays)","alpha2":"ie","alpha3":"irl"},
                {"id":352,"name":"Islande","alpha2":"is","alpha3":"isl"},
                {"id":376,"name":"Israël","alpha2":"il","alpha3":"isr"},
                {"id":380,"name":"Italie","alpha2":"it","alpha3":"ita"},
                {"id":388,"name":"Jamaïque","alpha2":"jm","alpha3":"jam"},
                {"id":392,"name":"Japon","alpha2":"jp","alpha3":"jpn"},
                {"id":400,"name":"Jordanie","alpha2":"jo","alpha3":"jor"},
                {"id":398,"name":"Kazakhstan","alpha2":"kz","alpha3":"kaz"},
                {"id":404,"name":"Kenya","alpha2":"ke","alpha3":"ken"},
                {"id":417,"name":"Kirghizistan","alpha2":"kg","alpha3":"kgz"},
                {"id":296,"name":"Kiribati","alpha2":"ki","alpha3":"kir"},
                {"id":414,"name":"Koweït","alpha2":"kw","alpha3":"kwt"},
                {"id":418,"name":"Laos","alpha2":"la","alpha3":"lao"},
                {"id":426,"name":"Lesotho","alpha2":"ls","alpha3":"lso"},
                {"id":428,"name":"Lettonie","alpha2":"lv","alpha3":"lva"},
                {"id":422,"name":"Liban","alpha2":"lb","alpha3":"lbn"},
                {"id":430,"name":"Liberia","alpha2":"lr","alpha3":"lbr"},
                {"id":434,"name":"Libye","alpha2":"ly","alpha3":"lby"},
                {"id":438,"name":"Liechtenstein","alpha2":"li","alpha3":"lie"},
                {"id":440,"name":"Lituanie","alpha2":"lt","alpha3":"ltu"},
                {"id":442,"name":"Luxembourg (pays)","alpha2":"lu","alpha3":"lux"},
                {"id":807,"name":"Macédoine du Nord","alpha2":"mk","alpha3":"mkd"},
                {"id":450,"name":"Madagascar","alpha2":"mg","alpha3":"mdg"},
                {"id":458,"name":"Malaisie","alpha2":"my","alpha3":"mys"},
                {"id":454,"name":"Malawi","alpha2":"mw","alpha3":"mwi"},
                {"id":462,"name":"Maldives","alpha2":"mv","alpha3":"mdv"},
                {"id":466,"name":"Mali","alpha2":"ml","alpha3":"mli"},
                {"id":470,"name":"Malte","alpha2":"mt","alpha3":"mlt"},
                {"id":504,"name":"Maroc","alpha2":"ma","alpha3":"mar"},
                {"id":584,"name":"Îles Marshall (pays)","alpha2":"mh","alpha3":"mhl"},
                {"id":480,"name":"Maurice (pays)","alpha2":"mu","alpha3":"mus"},
                {"id":478,"name":"Mauritanie","alpha2":"mr","alpha3":"mrt"},
                {"id":484,"name":"Mexique","alpha2":"mx","alpha3":"mex"},
                {"id":583,"name":"États fédérés de Micronésie (pays)","alpha2":"fm","alpha3":"fsm"},
                {"id":498,"name":"Moldavie","alpha2":"md","alpha3":"mda"},
                {"id":492,"name":"Monaco","alpha2":"mc","alpha3":"mco"},
                {"id":496,"name":"Mongolie","alpha2":"mn","alpha3":"mng"},
                {"id":499,"name":"Monténégro","alpha2":"me","alpha3":"mne"},
                {"id":508,"name":"Mozambique","alpha2":"mz","alpha3":"moz"},
                {"id":104,"name":"Birmanie","alpha2":"mm","alpha3":"mmr"},
                {"id":516,"name":"Namibie","alpha2":"na","alpha3":"nam"},
                {"id":520,"name":"Nauru","alpha2":"nr","alpha3":"nru"},
                {"id":524,"name":"Népal","alpha2":"np","alpha3":"npl"},
                {"id":558,"name":"Nicaragua","alpha2":"ni","alpha3":"nic"},
                {"id":562,"name":"Niger","alpha2":"ne","alpha3":"ner"},
                {"id":566,"name":"Nigeria","alpha2":"ng","alpha3":"nga"},
                {"id":578,"name":"Norvège","alpha2":"no","alpha3":"nor"},
                {"id":554,"name":"Nouvelle-Zélande","alpha2":"nz","alpha3":"nzl"},
                {"id":512,"name":"Oman","alpha2":"om","alpha3":"omn"},
                {"id":800,"name":"Ouganda","alpha2":"ug","alpha3":"uga"},
                {"id":860,"name":"Ouzbékistan","alpha2":"uz","alpha3":"uzb"},
                {"id":586,"name":"Pakistan","alpha2":"pk","alpha3":"pak"},
                {"id":585,"name":"Palaos","alpha2":"pw","alpha3":"plw"},
                {"id":591,"name":"Panama","alpha2":"pa","alpha3":"pan"},
                {"id":598,"name":"Papouasie-Nouvelle-Guinée","alpha2":"pg","alpha3":"png"},
                {"id":600,"name":"Paraguay","alpha2":"py","alpha3":"pry"},
                {"id":528,"name":"Pays-Bas","alpha2":"nl","alpha3":"nld"},
                {"id":604,"name":"Pérou","alpha2":"pe","alpha3":"per"},
                {"id":608,"name":"Philippines","alpha2":"ph","alpha3":"phl"},
                {"id":616,"name":"Pologne","alpha2":"pl","alpha3":"pol"},
                {"id":620,"name":"Portugal","alpha2":"pt","alpha3":"prt"},
                {"id":634,"name":"Qatar","alpha2":"qa","alpha3":"qat"},
                {"id":642,"name":"Roumanie","alpha2":"ro","alpha3":"rou"},
                {"id":826,"name":"Royaume-Uni","alpha2":"gb","alpha3":"gbr"},
                {"id":643,"name":"Russie","alpha2":"ru","alpha3":"rus"},
                {"id":646,"name":"Rwanda","alpha2":"rw","alpha3":"rwa"},
                {"id":659,"name":"Saint-Christophe-et-Niévès","alpha2":"kn","alpha3":"kna"},
                {"id":674,"name":"Saint-Marin","alpha2":"sm","alpha3":"smr"},
                {"id":670,"name":"Saint-Vincent-et-les-Grenadines","alpha2":"vc","alpha3":"vct"},
                {"id":662,"name":"Sainte-Lucie","alpha2":"lc","alpha3":"lca"},
                {"id":90,"name":"Îles Salomon","alpha2":"sb","alpha3":"slb"},
                {"id":882,"name":"Samoa","alpha2":"ws","alpha3":"wsm"},
                {"id":678,"name":"Sao Tomé-et-Principe","alpha2":"st","alpha3":"stp"},
                {"id":686,"name":"Sénégal","alpha2":"sn","alpha3":"sen"},
                {"id":688,"name":"Serbie","alpha2":"rs","alpha3":"srb"},
                {"id":690,"name":"Seychelles","alpha2":"sc","alpha3":"syc"},
                {"id":694,"name":"Sierra Leone","alpha2":"sl","alpha3":"sle"},
                {"id":702,"name":"Singapour","alpha2":"sg","alpha3":"sgp"},
                {"id":703,"name":"Slovaquie","alpha2":"sk","alpha3":"svk"},
                {"id":705,"name":"Slovénie","alpha2":"si","alpha3":"svn"},
                {"id":706,"name":"Somalie","alpha2":"so","alpha3":"som"},
                {"id":729,"name":"Soudan","alpha2":"sd","alpha3":"sdn"},
                {"id":728,"name":"Soudan du Sud","alpha2":"ss","alpha3":"ssd"},
                {"id":144,"name":"Sri Lanka","alpha2":"lk","alpha3":"lka"},
                {"id":752,"name":"Suède","alpha2":"se","alpha3":"swe"},
                {"id":756,"name":"Suisse","alpha2":"ch","alpha3":"che"},
                {"id":740,"name":"Suriname","alpha2":"sr","alpha3":"sur"},
                {"id":748,"name":"Eswatini","alpha2":"sz","alpha3":"swz"},
                {"id":760,"name":"Syrie","alpha2":"sy","alpha3":"syr"},
                {"id":762,"name":"Tadjikistan","alpha2":"tj","alpha3":"tjk"},
                {"id":834,"name":"Tanzanie","alpha2":"tz","alpha3":"tza"},
                {"id":148,"name":"Tchad","alpha2":"td","alpha3":"tcd"},
                {"id":203,"name":"Tchéquie","alpha2":"cz","alpha3":"cze"},
                {"id":764,"name":"Thaïlande","alpha2":"th","alpha3":"tha"},
                {"id":626,"name":"Timor oriental","alpha2":"tl","alpha3":"tls"},
                {"id":768,"name":"Togo","alpha2":"tg","alpha3":"tgo"},
                {"id":776,"name":"Tonga","alpha2":"to","alpha3":"ton"},
                {"id":780,"name":"Trinité-et-Tobago","alpha2":"tt","alpha3":"tto"},
                {"id":788,"name":"Tunisie","alpha2":"tn","alpha3":"tun"},
                {"id":795,"name":"Turkménistan","alpha2":"tm","alpha3":"tkm"},
                {"id":792,"name":"Turquie","alpha2":"tr","alpha3":"tur"},
                {"id":798,"name":"Tuvalu","alpha2":"tv","alpha3":"tuv"},
                {"id":804,"name":"Ukraine","alpha2":"ua","alpha3":"ukr"},
                {"id":858,"name":"Uruguay","alpha2":"uy","alpha3":"ury"},
                {"id":548,"name":"Vanuatu","alpha2":"vu","alpha3":"vut"},
                {"id":862,"name":"Venezuela","alpha2":"ve","alpha3":"ven"},
                {"id":704,"name":"Viêt Nam","alpha2":"vn","alpha3":"vnm"},
                {"id":887,"name":"Yémen","alpha2":"ye","alpha3":"yem"},
                {"id":894,"name":"Zambie","alpha2":"zm","alpha3":"zmb"},
                {"id":716,"name":"Zimbabwe","alpha2":"zw","alpha3":"zwe"}
            ]
        }
    },
    computed: {
        user(){
            return this.$store.getters.getUser
        },
        countries(){
            const countries = []
            console.log(this.countriesData);
            this.countriesData.forEach(count => {
                countries.push({label: count.name})
            }); 
            return countries
        }
    },
    watch: {
        enterprise: {
            deep: true,
            handler(){
                if(this.enterprise.principalAccountNumber) this.existingAccountNumber = false
            }
        }
    },
    methods: {
        addDirigeant(dg){
            this.$refs.form.validateWithInfo().then(validate => {
                let { isValid, errors } = validate;
                console.log(isValid, errors)
                if(isValid) {
                    if(this.enterprise.dirigeants.length == this.dirigeantsIds.length) {
                        this.dirigeantNumber++
                        this.enterprise.dirigeants.push({
                            id: this.dirigeantNumber,
                            name: '',
                            type: '',
                            function: '',
                            birthDate: '',
                            birthCity: '',
                            nationality: '',
                            residenceCountry: '',
                            residenceCity: '',
                            capital: '',
                            idCardType: '',
                            idCardFile: ''
                        })
                    }
                    else {
                        instanceApi.post('/dirigeant',{
                            type: dg.type,
                            fullName: dg.name,
                            fonction: dg.function,
                            birthdate: dg.birthDate,
                            birthplace: dg.birthCity,
                            nationality: dg.nationality,
                            city: dg.residenceCity,
                            country: dg.residenceCountry,
                            capital: dg.capital,
                            pi: dg.idCardType,
                            piNumber: dg.idCardFile,
                        })
                        .then(res => {
                            console.log(res);
                            const drId =res.data.id
                            this.dirigeantsIds.push(drId)
                            this.dirigeantNumber++
                            this.enterprise.dirigeants.push({
                                id: this.dirigeantNumber,
                                name: '',
                                type: '',
                                function: '',
                                birthDate: '',
                                birthCity: '',
                                nationality: '',
                                residenceCountry: '',
                                residenceCity: '',
                                capital: '',
                                idCardType: '',
                                idCardFile: ''
                            })
                        })
                        .catch(err => {
                            console.log(err);
                        })
                    }

                }
            })
        },
        removeDirigeantNotAdd(){
            this.dirigeantNumber--
            this.enterprise.dirigeants.pop()
        },
        removeDirigeant(id){
            instanceApi.delete(`/dirigeant/${this.dirigeantsIds[id]}`)
            .then(res => {
                console.log(res);
                this.dirigeantsIds.splice(id, 1)
                this.dirigeantNumber--
                this.enterprise.dirigeants.splice(id, 1)
                this.enterprise.dirigeants.forEach((element, index) => {
                    element.id = index
                });
            })
            .catch(err => {
                console.log(err);
            })
        },
        addOtherBankName(){
            this.otherAccount++
            this.enterprise.otherBankNames.push('')
        },
        removeOtherBankName(){
            this.otherAccount--
            this.enterprise.otherBankNames.pop()
        },
        onSubmit(){
            this.$refs.form.validateWithInfo().then(validate => {
                let { isValid, errors } = validate;
                console.log(isValid, errors)
                if(isValid) {
                    if(this.step == this.totalSteps) {
                        this.$router.push('/dashboard/success')
                        const enterprise = this.enterprise
                        instanceApi.post('/companie',
                            {
                                accountNumber: enterprise.principalAccountNumber,
                                legalStatus: enterprise.juridicForm,
                                companyName: enterprise.socialReason,
                                companyType: enterprise.societyType,
                                createdDate: enterprise.creationDate,
                                activityType: enterprise.activityNature,
                                immatriculationDate: enterprise.immatDate,
                                rccm: enterprise.rccmImmat,
                                capital: enterprise.socialCapital,
                                taxpayAccountNumber: enterprise.contribAccountNumb,
                                nationality: enterprise.nationality,
                                postalAdresse: enterprise.postAddress,
                                adresse: enterprise.geoAddress,
                                officePlace: enterprise.socialSiege,
                                country: enterprise.country,
                                fax: enterprise.fax,
                                email: enterprise.email,
                                phoneNumber: enterprise.phone,
                                webSite: enterprise.webSite,
                                ca: enterprise.avgBusinessMoney,
                                agreementNumber: enterprise.autAgreement,
                                degreeNumber: enterprise.ministerialDecret,
                                hasAnotherAccount: enterprise.haveOtherBankAccount,
                                dirigeants: this.dirigeantsIds,
                                userId: this.user.id,
                                banques: this.enterprise.otherBankNames
                            }
                        )
                        .then(res => {
                            console.log(res);
                            const userData = this.$store.getters.getUser;
                            this.$store.commit('mutUserData', {
                                token: userData.token,
                                profile: userData.profile,
                                id: userData.id,
                                hasSurvey: true,
                                username: userData.username,
                                lastname: userData.lastName,
                                firstname: userData.firstName
                            })
                            this.$router.push('/dashboard/success')
                        })
                        .catch(err => {
                            console.log(err.response.data);
                            if(err.response.data.code == 11000) this.existingAccountNumber = true
                        }) 
                       
                    }
                    else if (this.step == 2){
                        if(this.enterprise.dirigeants.length == this.dirigeantsIds.length) {
                            this.step++
                        }
                        else {
                            const lastDg = this.enterprise.dirigeants[this.enterprise.dirigeants.length - 1]
                            instanceApi.post('/dirigeant',{
                                type: lastDg.type,
                                fullName: lastDg.name,
                                fonction: lastDg.function,
                                birthdate: lastDg.birthDate,
                                birthplace: lastDg.birthCity,
                                nationality: lastDg.nationality,
                                city: lastDg.residenceCity,
                                country: lastDg.residenceCountry,
                                capital: lastDg.capital,
                                pi: lastDg.idCardType,
                                piNumber: lastDg.idCardFile,
                            })
                            .then(res => {
                                console.log(res);
                                const drId =res.data.id
                                this.dirigeantsIds.push(drId)
                                this.step++
                            })
                            .catch(err => {
                                console.log(err);
                            })
                        }
                    }
                    else {
                        this.step++
                    }
                }
            })
        }
    },
    async beforeCreate(){
        /* const country = await directAxios.get('https://api.countrylayer.com/v2/all', {params: {access_key: '7bab2e05d7b516562456bdc8faa9121d'}})
        const data = country.data
        console.log(data); */
    }
}
</script>

<style scoped>

</style>