<template>
    <div class="mb-12">
        <div v-if="type == ''" class="mx-auto pt-14 lg:pt-28 flex flex-col items-center space-y-6">
            <p class="lg:text-2xl font-bold">Quel type de client êtes vous ?</p>
            <div @click="type = 'particular'">
                <PersonTypeCard>
                    <span slot="icon">
                        <svgicon name="particular" width="60" height="60" ></svgicon>
                    </span>
                    <span slot="title">Je suis un particulier</span>
                </PersonTypeCard> 
            </div>
            
            <div @click="type = 'enterprise'">
                <PersonTypeCard>
                    <span slot="icon">
                        <svgicon name="enterprise" width="60" height="60" ></svgicon>
                    </span>
                    <span slot="title">Je suis une entreprise</span>
                </PersonTypeCard>
            </div>
        </div>

        <div v-else class="mt-4 md:mt-12">
            <Particular v-if="type == 'particular'" @type="type = $event"></Particular>
            <Enterprise v-if="type == 'enterprise'" @type="type = $event"></Enterprise>
        </div>
    </div>
</template>

<script>
import Particular from '../CollectForm/ParticularForm'
import Enterprise from '../CollectForm/EnterpriseForm'
import PersonTypeCard from '../others/PersonTypeCard'
export default {
    data() {
        return {
            choosingType: true,
            type: ''
        }
    },
    components: {
        PersonTypeCard,
        Particular,
        Enterprise
    },
    computed: {
        
    },
    methods: {
        
    },
    mounted() {
        this.$emit('emitBg', 'bg-bgGrey')
    },
    updated() {
        if(this.type == '') this.$emit('emitBg', 'bg-bgGrey')
        else {
            this.$emit('emitBg', 'bg-white')
        }
    }
}
</script>

<style scoped>

</style>