<template>
    <div class="px-4 md:px-16">
        <div class="flex space-x-4">
            <div @click="$emit('type', '')">
                <svgicon name="chevronPrev" width="36" height="36" ></svgicon>
            </div>
            <div>
                <p class="lg:text-2xl font-bold">Formulaire de collecte des données pour particulier</p>
                <p class="mt-1 text-sm lg:text-base">Mise à jour de la base de données afin de répondre aux exigences légales et règlementaires</p>
            </div>
        </div>
        <p class="my-6 flex space-x-4 items-center bg-tint70 rounded-2xl p-3 py-2.5">
            <span>
                <svgicon name="infoCircle" width="44" height="44" ></svgicon>
            </span>
            <span class="text-sm">La BNI respecte votre droit à la vie privée. Il est important que vous ayez la maîtrise de vos données à caractère personnel. De ce fait, vous avez le droit d’accéder aux informations que nous traitons sur vous. <span @click="seeMod=true" class="text-blueNormal underline cursor-pointer">En Savoir plus</span></span>
        </p>
        <p v-if="existingAccountNumber" class="my-6 flex space-x-4 items-center bg-errorBg rounded-lg p-3 py-2.5">
            <span>
                <svg width="28" height="28" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="1" y="1" width="14" height="14">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.00004 1.3335C4.32004 1.3335 1.33337 4.32016 1.33337 8.00016C1.33337 11.6802 4.32004 14.6668 8.00004 14.6668C11.68 14.6668 14.6667 11.6802 14.6667 8.00016C14.6667 4.32016 11.68 1.3335 8.00004 1.3335ZM8.00004 8.66683C7.63337 8.66683 7.33337 8.36683 7.33337 8.00016V5.3335C7.33337 4.96683 7.63337 4.66683 8.00004 4.66683C8.36671 4.66683 8.66671 4.96683 8.66671 5.3335V8.00016C8.66671 8.36683 8.36671 8.66683 8.00004 8.66683ZM7.33337 10.0002V11.3335H8.66671V10.0002H7.33337Z" fill="black" />
                    </mask>
                    <g mask="url(#mask0)">
                        <rect width="16" height="16" fill="#DA1414" />
                    </g>
                </svg>
            </span>
            <span class="text-sm lg:text-lg">Le numéro de compte entré à dejà été enregistré pour une collecte</span>
        </p>
        
        <div class="mb-6 text-xs flex justify-center items-center">
            <div class="flex space-x-3 items-center font-medium w-1/4 justify-center border-b-2 border-secondary pb-3" :class="step==1 ? 'border-primary' : step > 1 ? 'border-stepPass' : ''">
                <p class="mb-1 rounded-full flex items-center justify-center" :class="step == 1 ? 'text-white h-8 w-8 bg-primary text-lg' : (step > 1 ? 'h-8 w-8 bg-stepPass text-black' : 'h-8 w-8  bg-bgGrey  text-fonctGrey')">
                    <span v-if="step <= 1">1</span>
                    <span v-else> <svgicon name="checkStep" width="20" height="20" ></svgicon></span>
                </p>
                <p class="hidden md:inline text-center" :class="step == 1 ? 'text-black' : (step > 1 ? 'text-blueDark':'text-fonctGrey')">Identification</p>
            </div>
            <div class="flex space-x-3 items-center font-medium w-1/4 justify-center border-b-2 border-secondary pb-3" :class="step==2 ? 'border-primary' : step > 2 ? 'border-stepPass' : ''">
                <p class="mb-1 rounded-full flex items-center justify-center" :class="step == 2 ? 'text-white h-8 w-8 bg-primary text-lg' : (step > 2 ? 'h-8 w-8 bg-stepPass text-black' : 'h-8 w-8  bg-bgGrey  text-fonctGrey')">
                    <span v-if="step <= 2">2</span>
                    <span v-else> <svgicon name="checkStep" width="20" height="20" ></svgicon></span>
                </p>
                <p class="hidden md:inline text-center" :class="step == 2 ? 'text-black' : (step > 2 ? 'text-blueDark':'text-fonctGrey')">Information sur l’activité</p>
            </div>
            <div  class="flex space-x-3 items-center font-medium w-1/4 justify-center border-b-2 border-secondary pb-3" :class="step==3 ? 'border-primary' : step > 3 ? 'border-stepPass' : ''">
                <p class="mb-1 rounded-full flex items-center justify-center" :class="step == 3 ? 'text-white h-8 w-8 bg-primary text-lg' : (step > 3 ? 'h-8 w-8 bg-stepPass text-black' : 'h-8 w-8  bg-bgGrey  text-fonctGrey')">
                    <span v-if="step <= 3">3</span>
                    <span v-else> <svgicon name="checkStep" width="20" height="20" ></svgicon></span>
                </p>
                <p class="hidden md:inline text-center" :class="step == 3 ? 'text-black' : (step > 3 ? 'text-blueDark':'text-fonctGrey')">Information sur l’employeur</p>
            </div>
            <div  class="flex space-x-3 items-center font-medium w-1/4 justify-center border-b-2 border-secondary pb-3" :class="step==4 ? 'border-primary' : step > 4 ? 'border-stepPass' : ''">
                <p class="mb-1 rounded-full flex items-center justify-center" :class="step == 4 ? 'text-white h-8 w-8 bg-primary text-lg' : (step > 4 ? 'h-8 w-8 bg-stepPass text-black' : 'h-8 w-8  bg-bgGrey  text-fonctGrey')">
                    <span v-if="step <= 4">4</span>
                    <span v-else> <svgicon name="checkStep" width="20" height="20" ></svgicon></span>
                </p>
                <p class="hidden md:inline text-center" :class="step == 4 ? 'text-black' : (step > 4 ? 'text-blueDark':'text-fonctGrey')">Référence bancaire</p>
            </div>
        </div>

        <div class="">
            <ValidationObserver ref="form">
                <form @submit.prevent="onSubmit" class="">
                    <div v-if="step == 1">
                        <div class="mb-6">
                            <ValidationProvider name="Le numéro de compte" rules="required|max:11" v-slot="{ errors }">
                                <m-input name = "accountNumber" v-model="particular.accountNumber" :errors="errors[0]"  type="" min="0" maxLength="11" label="Numéro  de compte <span class='text-red-500'>*</span>"/>
                            </ValidationProvider>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le nom" rules="required" v-slot="{ errors }">
                                    <m-input name = "name" v-model="particular.lastname" :errors="errors[0]"  type="" min="0" label="Nom <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider >
                                    <m-input v-model="particular.youngGirlName" name = "youngName" label="Nom de jeune fille"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le prénom" rules="required" v-slot="{ errors }">
                                    <m-input name = "surname" v-model="particular.firstname" :errors="errors[0]"  label="Prénoms <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La Civilité" rules="required" v-slot="{ errors }">
                                    <label class="font-bold" for="">Civilité <span class='text-red-500'>*</span></label>
                                    <m-select :errors="errors[0]" class="mt-1" v-model="particular.civility" :initialValue="particular.civility" :options="civilityOptions" placeholder="Cliquer pour sélectionner" ></m-select>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La date de naissance" rules="required" v-slot="{ errors }">
                                    <vc-date-picker v-model="particular.birthDate" :max-date="new Date()">
                                        <template v-slot="{ inputValue, inputEvents }">
                                            <m-input name = "birth" :value="inputValue" v-on="inputEvents" :errors="errors[0]"  label="Date de naissance <span class='text-red-500'>*</span>">
                                                <span slot="prepend"><svgicon name="calendar" width="24" height="25"></svgicon></span>
                                            </m-input>
                                        </template>
                                    </vc-date-picker>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le lieu de naissance" rules="required" v-slot="{ errors }">
                                    <m-input name = "birthCity" v-model="particular.birthCity" :errors="errors[0]" label="Lieu de naissance <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La nationalité" rules="required" v-slot="{ errors }">
                                    <m-input name = "nationality" v-model="particular.nationality" :errors="errors[0]"  label="Nationalité <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La situation matrimoniale" rules="required" v-slot="{ errors }">
                                    <label class="font-bold" for="">Situation matrimoniale <span class='text-red-500'>*</span></label>
                                    <m-select :errors="errors[0]" v-model="particular.matrimonial" class="mt-1" :initialValue="particular.matrimonial" :options="matrimonialOptions" placeholder="Cliquer pour sélectionner" ></m-select>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider >
                                    <m-input v-model="particular.partenaryName" name = "partenaryName" label="Nom du conjoint(e)"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le nombre d’enfants" rules="numeric" v-slot="{ errors }">
                                    <m-input name = "children" v-model="particular.childrenNumber" :errors="errors[0]"  type="" min="0" label="Nombre d’enfants"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le nom de jeune fille de la mère" rules="required" v-slot="{ errors }">
                                    <m-input name = "motherName" v-model="particular.motherName" :errors="errors[0]"  type="" label="Nom de jeune fille de la mère <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le prénom de la mère" rules="required" v-slot="{ errors }">
                                    <m-input name = "motherFirstName" v-model="particular.motherFirstName" :errors="errors[0]"  type="" label="Prénoms de la mère <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le nom du père" rules="required" v-slot="{ errors }">
                                    <m-input name = "fatherName" v-model="particular.fatherName" :errors="errors[0]"  type="" label="Nom du père <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le prénom du père" rules="required" v-slot="{ errors }">
                                    <m-input name = "fatherFirstName" v-model="particular.fatherFirstName" :errors="errors[0]"  type="" label="Prénoms du père <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le numéro de téléphone" rules="required|numeric" v-slot="{ errors }">
                                    <m-input name = "phone" v-model="particular.phone1" :errors="errors[0]"  type="" min="0" label="Numéro de téléphone <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="Le numéro de téléphone 2" rules="numeric" v-slot="{ errors }">
                                    <m-input name = "phone2" v-model="particular.phone2" :errors="errors[0]"  type="" min="0" label="Numéro de téléphone 2"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="mb-6">
                            <ValidationProvider name="L'adresse géographique">
                                <m-input name = "address" v-model="particular.geoAddress"  type=""  label="Adresse géographique"/>
                            </ValidationProvider>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <p class="font-bold mb-4 text-sm">Client résident en côte d’ivoire ? <span class='text-red-500'>*</span></p>
                                <p class="flex space-x-10 items-center font-medium text-sm">
                                    <label for="">
                                        <input type="radio" :value="true" v-model="particular.ivoryResident" name="residence" checked class="form-radio text-blueNormal border border-blue-600" id=""> Oui
                                    </label>
                                    <label for="">
                                            <input type="radio" :value="false" v-model="particular.ivoryResident" name="residence" class="form-radio text-blueNormal border border-blue-600" id=""> Non
                                    </label>
                                </p>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="L'adresse postale du client ">
                                    <m-input name = "post" v-model="particular.postAddress"  type="" label="Adresse postale du client"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La nature de la pièce d’identité " rules="required" v-slot="{ errors }">
                                    <label class="font-bold text-sm" for="">Nature de la pièce d’identité  <span class='text-red-500'>*</span></label>
                                    <m-select :errors="errors[0]" :putOnTop="'bottom-12'" v-model="particular.idCard.type" class="mt-1" :initialValue="particular.idCard.type" :options="idTypeOptions" placeholder="Cliquer pour sélectionner" ></m-select>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La pièce d’identité" rules="required" v-slot="{ errors }">
                                    <label class="font-bold" for="">Pièce d’identité <span v-if="particular.idCard.type != 'Passeport'">(Recto)</span> <span class='text-red-500'>*</span></label>
                                    <m-file-input v-model="particular.idCard.file" :errors="errors[0]" ></m-file-input>
                                </ValidationProvider>
                            </div>
                            <div v-if="particular.idCard.type != 'Passeport'" class="mb-6 md:w-1/2">
                                <ValidationProvider name="La pièce d’identité verso" rules="required" v-slot="{ errors }">
                                    <label class="font-bold" for="">Pièce d’identité <span v-if="particular.idCard.type != 'Passeport'">(Verso)</span> <span class='text-red-500'>*</span></label>
                                    <m-file-input v-model="particular.idCard.fileVerso" :errors="errors[0]" ></m-file-input>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La numéro de la pièce d’identité " rules="required" v-slot="{ errors }">
                                    <m-input name = "idNumber" v-model="particular.idCard.number" :errors="errors[0]"  type="" min="0" label="Numéro de la pièce d’identité <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2 flex space-x-4">
                                <div class="w-1/2">
                                    <ValidationProvider name="La validité " rules="required" v-slot="{ errors }">
                                        <vc-date-picker v-model="particular.idCard.validity" :min-date="new Date()">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <m-input name = "validity" :value="inputValue" v-on="inputEvents" :errors="errors[0]"  label="Validité <span class='text-red-500'>*</span>">
                                                    <span slot="prepend"><svgicon name="calendar" width="24" height="25"></svgicon></span>
                                                </m-input>
                                            </template>
                                        </vc-date-picker>
                                    </ValidationProvider>
                                </div>
                                <div class="w-1/2">
                                    <ValidationProvider name="La date d’étabilissement " rules="required" v-slot="{ errors }">
                                        <vc-date-picker v-model="particular.idCard.emitDate" :max-date="new Date()">
                                            <template v-slot="{ inputValue, inputEvents }">
                                                <m-input name = "date" :value="inputValue" v-on="inputEvents" :errors="errors[0]"  label="Date d’étabilissement <span class='text-red-500'>*</span>">
                                                    <span slot="prepend"><svgicon name="calendar" width="24" height="25"></svgicon></span>
                                                </m-input>
                                            </template>
                                        </vc-date-picker>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="step == 2">
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La profession" rules="required" v-slot="{ errors }">
                                    <m-input v-model="particular.profession" :errors="errors[0]" name = "name"  label="Profession <span class='text-red-500'>*</span>"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La Nature de contrat" :rules="'required'" v-slot="{ errors }">
                                    <label class="font-bold" for="">Nature de contrat <span class='text-red-500'>*</span></label>
                                    <m-select  :errors="errors[0]" v-model="particular.contrat" class="mt-1" :initialValue="particular.contrat" :options="contratOptions" placeholder="Cliquer pour sélectionner" ></m-select>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider name="La tranche de revenu professionnel" rules="required" v-slot="{ errors }">
                                    <label class="font-bold" for="">Tranche de revenu professionnel <span class='text-red-500'>*</span></label>
                                    <m-select :errors="errors[0]" v-model="particular.revenuProfes" class="mt-1" :initialValue="particular.revenuProfes" :options="revenuOptions" placeholder="Cliquer pour sélectionner" ></m-select>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider >
                                    <m-input name = "othersSource" v-model="particular.otherSourceRevenu" label="Autres sources de revenus"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="mb-6">
                            <ValidationProvider >
                                <m-input name = "othersRevenus" v-model="particular.otherRevenu" label="Autres revenus"/>
                            </ValidationProvider>
                        </div>
                        <div class="mb-6 md:w-1/2">
                            <p class="font-bold mb-4 text-sm">Avez-vous des activités annexes ? </p>
                            <p class="flex space-x-10 items-center font-medium text-sm">
                                <label for="">
                                    <input type="radio" v-model="particular.annexeActivity" :value="true" name="annexeActivity" class="form-radio text-blueNormal border border-blue-600" id=""> Oui
                                </label>
                                <label for="">
                                        <input type="radio" v-model="particular.annexeActivity" :value="false" name="annexeActivity" class="form-radio text-blueNormal border border-blue-600" id=""> Non
                                </label>
                            </p>
                        </div>
                        <div class="mb-6" v-if="particular.annexeActivity">
                            <ValidationProvider >
                                <m-input name = "othersSource" v-model="particular.othersActivity"  label="Citez vos activités annexes"/>
                            </ValidationProvider>
                        </div>
                    </div>

                    <div v-if="step == 3">
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider>
                                    <m-input name = "sigle" v-model="particular.employeeInfos.sigle" label="Sigle"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider>
                                    <m-input name = "localisation" v-model="particular.employeeInfos.localisation"  label="Localisation"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider >
                                    <m-input name = "social" v-model="particular.employeeInfos.socialRaison" label="Raison sociale"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider>
                                    <m-input name = "post" v-model="particular.employeeInfos.postAddress"  label="Adresse postale"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider>
                                    <m-input name = "activitySector" v-model="particular.employeeInfos.activitySector" label="Secteur d’activité"/>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2">
                                <ValidationProvider rules="numeric" name="Le téléphone" v-slot="{ errors }">
                                    <m-input name = "phone" :errors="errors[0]" v-model="particular.employeeInfos.phone"  label="Téléphone"/>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>

                    <div v-if="step == 4">
                        <div class="flex flex-col md:flex-row md:space-x-6 justify-between">
                            <div class="mb-6" :class="particular.otherBank.disposeAccount ? 'md:w-1/2':''">
                                <ValidationProvider >
                                    <p class="font-bold mb-4 text-sm">Le client dispose-t-il d’un compte dans une autre banque ? </p>
                                    <p class="flex space-x-10 items-center font-medium text-sm">
                                        <label for="">
                                            <input type="radio" v-model="particular.otherBank.disposeAccount" :value="true" name="disposeAccount" class="form-radio text-blueNormal border border-blue-600" id=""> Oui
                                        </label>
                                        <label for="">
                                                <input type="radio" v-model="particular.otherBank.disposeAccount" :value="false" name="disposeAccount" class="form-radio text-blueNormal border border-blue-600" id=""> Non
                                        </label>
                                    </p>
                                </ValidationProvider>
                            </div>
                            <div class="mb-6 md:w-1/2" v-if="particular.otherBank.disposeAccount">
                                <ValidationProvider name="Le numéro de compte" rules="required|max:11" v-slot="{ errors }">
                                    <m-input name = "accountNumber" v-model="particular.otherBank.accountNumber" maxlength="11" :errors="errors[0]" label="Indiquez le numéro de compte"/>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="mb-6" v-if="particular.otherBank.disposeAccount">
                            <ValidationProvider name="Le nom de la banque" rules="required" v-slot="{ errors }">
                                <m-input name = "bankName" v-model="particular.otherBank.bank" :errors="errors[0]" label="Indiquez le nom de la banque"/>
                            </ValidationProvider>
                        </div>
                    </div>
                    <div class="flex space-x-4 md:space-x-6 justify-between">
                        <div :class="step !=1 ? 'w-1/2' : 'hidden'">
                            <m-button @click="step--" :classes="'font-bold text-sm sm:text-base bg-bgGrey rounded-lg p-2 w-full'" :text="'Retour'"></m-button>
                        </div>
                        <div v-if="step == 1" class="md:w-1/2"></div>
                        <div :class="step !=1 ? 'w-1/2' : 'md:w-1/2'">
                            <m-button :type="'submit'" :classes="'text-white font-bold text-sm sm:text-base bg-primary rounded-lg p-2 w-full'" load :activateLoad="request" :text="'Valider'"></m-button>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </div>
        <Modal ref="modal" centered size="sm" :css="deliveryCss" :show="seeMod">
            <div slot="modal-header">
                <div class="flex space-x-24 font-medium justify-between" @click="seeMod=false">
                    <p class=" text-xl md:text-2xl">La BNI respecte votre droit à la vie privée.</p>
                    <p class="cursor-pointer"><svgicon name="closeMod" class="h-6 md:h-8 w-6 md:w-8"></svgicon></p>
                </div>
            </div>
            <div class="mt-3 text-sm font-medium flex flex-col space-y-2" slot="modal-body">
                <p>Il est important que vous ayez la maîtrise de vos données à caractère personnel. De ce fait, vous avez le droit d’accéder aux informations que nous traitons sur vous.</p>
                <p>Vous avez le droit de saisir et d’introduire une réclamation auprès de l’Autorité de protection des données du pays pour contester les pratiques de la BNI si ces pratiques portes atteinte à votre vie privée, à vos droits et libertés.</p>
                <p>Afin d’exercer vos droits ou si vous avez des préoccupations, veuillez vous adresser à:</p>
                <div class="flex flex-col space-y-4">
                    <p class="flex items-center space-x-2">
                        <span><svgicon name="mailIcon" class="h-8 w-8"></svgicon></span>
                        <span>correspondantpdcp@bni.ci</span>
                    </p>
                    <p class="flex items-center space-x-2">
                        <span><svgicon name="phoneIcon" class="h-8 w-8"></svgicon></span>
                        <span>27 20 30 30 30</span>
                    </p>
                    <p class="flex items-center space-x-2">
                        <span><svgicon name="addrIcon" class="h-8 w-8"></svgicon></span>
                        <span>Siège social sis à Avenue Marchand Immeuble SCIAM Plateau-Abidjan-Côte d’Ivoire.</span>
                    </p>
                </div>
                <m-button @click="seeMod=false" :classes="'mt-5 text-white font-bold text-sm sm:text-base bg-primary rounded-lg p-2 w-full'" :text="'J’ai compris'"></m-button>
            </div>
        </Modal>
    </div>
</template>

<script>
//:rules="['etudiant','étudiant', 'étudiante', 'etudiante'].includes(particular.profession) ? '' : 'required'"
import axios from 'axios'
const token = localStorage.getItem('token')
const header = { "Authorization": `Bearer ${token}` }
const instanceApi = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    headers: header
})
export default {
    data() {
        return {
            existingAccountNumber: false,
            request: false,
            seeMod: false,
            deliveryCss: {modalClass:"p-4 md:px-6 overflow-x-hidden overflow-y-auto hiddScroll"},
            step: 1,
            totalSteps: 4,
            civilityOptions: [
                {label:'Monsieur'},
                {label:'Madame'},
                {label:'Mademoiselle'},
            ],
            matrimonialOptions: [
                {label:'Célibataire'},
                {label:'Marié'},
                {label:'Divorcé'},
                {label:'Concubinage'},
            ],
            idTypeOptions: [
                {label:'CNI'},
                {label:'Passeport'},
                {label:'Carte consulaire'},
                {label:'Attestation d’identité'},
                {label:'Carte de séjour'},
            ],
            contratOptions: [
                {label:'CDD'},
                {label:'CDI'},
                {label:'INTERIMAIRE'},
                {label:'STAGIARE'},
                {label:'Pas de contrat'},
                {label:'Autre'},
            ],
            revenuOptions: [
                {label:'Inférieur à 200 milles'},
                {label:'Entre 200 milles et 700 milles'},
                {label:'Entre 700 milles et 2 millions'},
                {label:'Supérieur à 2 millions'},
            ],
            otherBankOptions: [
                {label:'Oui'},
                {label:'Non'}
            ],
            particular: {
                accountNumber: '',
                lastname: '',
                youngGirlName: '',
                firstname: '',
                civility: '',
                birthDate: '',
                birthCity: '',
                nationality: '',
                matrimonial: '',
                partenaryName: '',
                childrenNumber: '',
                motherName: '',
                motherFirstName: '',
                fatherName: '',
                fatherFirstName: '',
                phone1: '',
                phone2: '',
                geoAddress: '',
                ivoryResident: true,
                postAddress: '',
                idCard: {
                    type: '',
                    file: '',
                    fileVerso: '',
                    number: '',
                    validity: '',
                    emitDate: ''
                },
                profession: '',
                contrat: '',
                revenuProfes: '',
                otherSourceRevenu: '',
                otherRevenu: '',
                annexeActivity: false,
                othersActivity: '',
                employeeInfos: {
                    sigle: '',
                    localisation: '',
                    socialRaison: '',
                    postAddress: '',
                    activitySector: '',
                    phone: '',
                },
                otherBank: {
                    disposeAccount: false,
                    accountNumber: '',
                    bank: ''
                }
            }
        }
    },
    computed: {
        user(){
            return this.$store.getters.getUser
        }
    },
    watch: {
        particular: {
            deep: true,
            handler(){
                if(this.particular.accountNumber) this.existingAccountNumber = false
            }
        }
    },
    methods: {
        
        onSubmit(){
            this.$refs.form.validateWithInfo().then(validate => {
                let { isValid, errors } = validate;
                console.log(isValid, errors)
                if(isValid) {
                    if(this.step == this.totalSteps) {
                        const person = this.particular
                        instanceApi.post('/person',
                            {
                                accountNumber: person.accountNumber,
                                firstName: person.firstname,
                                lastName: person.lastname,
                                parentName: person.youngGirlName,
                                civility: person.civility,
                                birthdate: person.birthDate,
                                birthplace: person.birthCity,
                                nationality: person.nationality,
                                maritalStatus: person.matrimonial,
                                partnerName: person.partenaryName,
                                numberChildren: person.childrenNumber,
                                motherFirstName: person.motherFirstName,
                                motherLastName: person.motherName,
                                fatherFirstName: person.fatherFirstName,
                                fatherLastName: person.fatherName,
                                phoneNumber: person.phone1,
                                otherNumber: person.phone2,
                                adresse: person.geoAddress,
                                isResident: person.ivoryResident,
                                postalAdresse: person.postAddress,
                                pi: person.idCard.type,
                                piUrl: person.idCard.file,
                                piUrlVerso: person.idCard.fileVerso,
                                piNumber: person.idCard.number,
                                piValidyDate: person.idCard.validity,
                                piEtablishmentDate: person.idCard.emitDate,
                                profession: person.profession,
                                contract: person.contrat,
                                salaria: person.revenuProfes,
                                earningSource: person.otherSourceRevenu,
                                otherEarning: person.otherRevenu,
                                hasOtherActivies: person.annexeActivity,
                                otherActivities: person.othersActivity,
                                employerSigle: person.employeeInfos.sigle,
                                employerLocalisation: person.employeeInfos.localisation,
                                employerCompanyName: person.employeeInfos.socialRaison,
                                employerAdresse: person.employeeInfos.postAddress,
                                employerActivitySector: person.employeeInfos.activitySector,
                                employerPhone: person.employeeInfos.phone,
                                hasAnotherAccount: person.otherBank.disposeAccount,
                                anotherAccountNumber: person.otherBank.accountNumber,
                                userId: this.user.id,
                                banques: person.otherBank.bank
                            }
                        )
                        .then(res => {
                            console.log(res);
                            const userData = this.$store.getters.getUser;
                            this.$store.commit('mutUserData', {
                                token: userData.token,
                                profile: userData.profile,
                                id: userData.id,
                                hasSurvey: true,
                                username: userData.username,
                                lastname: userData.lastName,
                                firstname: userData.firstName
                            })
                            this.$router.push('/dashboard/success')
                        })
                        .catch(err => {
                            console.log(err.response.data);
                            if(err.response.data.code == 11000) this.existingAccountNumber = true
                        })
                    }
                    else {
                        this.step++
                    }
                }
            })
        }
    },
}
</script>

<style scoped>

</style>